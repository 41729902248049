export const invData = [
  {
    nr: 54417,
    name: "Alaska Seelachs in Küstenbackt",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.13,
        text: "Stück",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56183,
    name: "Alaska Seelachs Knuspermantel",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53384,
    name: "Alaska-Seel. Trapez 180g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.178,
        text: "Stück",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53798,
    name: "Asia Dip",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50960,
    name: "ASIA REIS GEMUESEMISCH",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50147,
    name: "AUFLAGE FOERSTERIN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55535,
    name: "Baby Salatmix /Salatmisch.100g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56007,
    name: "Baby Salatmix 1X250g Frikoni",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },

      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52612,
    name: "Backfischschnitte 90g N-",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.09,
        text: "Stück",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52569,
    name: "BACKOELE",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
    ],
  },
  {
    nr: 56240,
    name: "Backvisch Plant Based 90g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.09,
        text: "Stück",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56017,
    name: "Backvish 90g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.09,
        text: "Stück",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51020,
    name: "BAGUETT VORGESCH",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.008,
        text: "Stück",
      },
    ],
  },
  {
    nr: 56014,
    name: "Baguettebrötchen soft dkl 60g",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 50433,
    name: "BISMARCKHERING 130ER MSC",
    unit: "EI",
    conversion: [
      {
        factor: 1,
        text: "EI",
      },
      {
        factor: 0.008,
        text: "Stück",
      },
    ],
  },
  {
    nr: 56226,
    name: "Bismarckhering 130er MSC",
    unit: "EI",
    conversion: [
      {
        factor: 1,
        text: "EI",
      },
      {
        factor: 0.008,
        text: "Stück",
      },
    ],
  },
  {
    nr: 53070,
    name: "BRATFETT",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53507,
    name: "Bratfischgewürz 500g-Dose",
    unit: "DO",
    conversion: [
      {
        factor: 1,
        text: "DO",
      },
    ],
  },
  {
    nr: 52568,
    name: "BRATKARTOFFELN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51002,
    name: "BREMER BROETCHEN TK",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.009,
        text: "ST",
      },
    ],
  },
  {
    nr: 56019,
    name: "Burger Bun 85g",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.042,
        text: "St",
      },
    ],
  },
  {
    nr: 50371,
    name: "CHAMPIGNONS FRISCH",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56037,
    name: "Cheddar Cheese OT",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
      {
        factor: 0.1,
        text: "ST",
      },
    ],
  },
  {
    nr: 56013,
    name: "Chili Creme mit Kapern",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56239,
    name: "Ciabatta Multi Grain 60x75g",
    unit: "ST",
  },
  {
    nr: 54434,
    name: "Ciabatta Peperoni viereckig",
    unit: "KA",
  },
  {
    nr: 50317,
    name: "COCKT. MARSEILLE",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52191,
    name: "COCKTAILGARNELEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54345,
    name: "Cole Slaw",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56011,
    name: "Demi Twist Multigrain",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 56010,
    name: "Demi Twist Red Pepper",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 51235,
    name: "DILLHAPPEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56155,
    name: "Dip Käse Mild vegan",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52577,
    name: "Div. GEMUESE / GASTRO KG",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56012,
    name: "Dressings Div. 100 ml",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.1,
        text: "St",
      },
    ],
  },
  {
    nr: 50516,
    name: "EIER GEK.ATM",
    unit: "ST",
    conversion: [
      {
        factor: 1,
        text: "ST",
      },
    ],
  },
  {
    nr: 55404,
    name: "Eiswürfel 12x1KG SOMMER 2015",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55676,
    name: "Espresso Bohne Melitta",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55315,
    name: "Fischfilet Premium",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52164,
    name: "FISCHFRIKADELL. 75G",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
      {
        factor: 0.013,
        text: "St",
      },
    ],
  },
  {
    nr: 51989,
    name: "FISCHSTAEBCHEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53773,
    name: "FISCHSUPPEN MIX",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52043,
    name: "FISCHSUPPENBASIS",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56164,
    name: "Fish Nuggets",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53853,
    name: "Fitness Piccolo Brötch.50St N-",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.02,
        text: "ST",
      },
    ],
  },
  {
    nr: 53188,
    name: "Flusskrebs Calvados",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52903,
    name: "Flusskrebssalat Italia",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56193,
    name: "Focaccia  Kürbis Kar. 45x130g",
    unit: "ST",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 0.022,
        text: "ST",
      },
    ],
  },
  {
    nr: 54322,
    name: "Frischkäse",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56100,
    name: "Fruchtiger Quinoa Mix mKo",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54055,
    name: "Frühlingszwiebeln/Lauch",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56153,
    name: "Garnele paniert plant based",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51866,
    name: "GARNELEN PANIERT",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52610,
    name: "GARNELEN PT + Grönland",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52646,
    name: "Garnelenspieß",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
      {
        factor: 0.075,
        text: "ST",
      },
    ],
  },
  {
    nr: 51044,
    name: "GEMUESEREIS",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53020,
    name: "Gemüsemischung für Salat",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56001,
    name: "Gemüsestreifen fein",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55994,
    name: "GO Chillies geschrotet",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55997,
    name: "GO Siracha-Salatcreme",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55993,
    name: "GO Thymian gerebelt",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55998,
    name: "GO Zitronen-Salatcreme",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51560,
    name: "GRAVED LACHS",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56182,
    name: "Grießpudding",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51211,
    name: "GRUNDSC WEISS",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50374,
    name: "GURKEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50546,
    name: "GURKENSCHEIBEN 10/1 DOSE",
    unit: "DO",
    conversion: [
      {
        factor: 1,
        text: "DO",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56232,
    name: "Happy Fisch Figuren",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52583,
    name: "HERINGSF.SAHNETUNKE  FKB",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51233,
    name: "HERINGSSAL.ROT.FK",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50119,
    name: "HONIG-SENF SC 200ML",
    unit: "GL",
    conversion: [
      {
        factor: 1,
        text: "GL",
      },
    ],
  },
  {
    nr: 55675,
    name: "Kaffee Bohne Melitta",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55677,
    name: "Kakao Melitta",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51017,
    name: "KARTOFFELECKEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52566,
    name: "KARTOFFELN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52567,
    name: "KARTOFFELSALAT",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51280,
    name: "Käse, gerieben",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54180,
    name: "Ketchup Tomate 20ml Btl.",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 55601,
    name: "Kichererbsen",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51707,
    name: "KRABBENFLEISCH",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51227,
    name: "KRABBENMAYONNAISE",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50045,
    name: "Kräutermarinade f. Garnelen",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54379,
    name: "Lachs Port.m.Haut TK  185g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53863,
    name: "Lachsportionen ohne Haut",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51699,
    name: "LANGUSTENSCHWANZ",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50376,
    name: "LAUCH",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50378,
    name: "LOLLO BIONDA FRISCH",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56171,
    name: "Mango-Maracuja-Kompott",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50439,
    name: "MATJ.FIL.ROTE BEETE 40ER",
    unit: "EI",
    conversion: [
      {
        factor: 1,
        text: "EI",
      },
      {
        factor: 0.025,
        text: "ST",
      },
    ],
  },
  {
    nr: 55155,
    name: "Matjesdoppelfilet - Stück",
    unit: "ST",
    conversion: [
      {
        factor: 1,
        text: "ST",
      },
    ],
  },
  {
    nr: 54128,
    name: "Matjesfilet Pfeffer Sauce",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50606,
    name: "MATJESFILET TK 85ER",
    unit: "EI",
    conversion: [
      {
        factor: 1,
        text: "EI",
      },
      {
        factor: 0.012,
        text: "ST",
      },
    ],
  },
  {
    nr: 51241,
    name: "MATJESSAHNETOPF",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52813,
    name: "Milch H- Tetra",
    unit: "LT",
    conversion: [
      {
        factor: 1,
        text: "LT",
      },
      {
        factor: 1,
        text: "KG",
      },
    ],
  },
  {
    nr: 52748,
    name: "Milchreis",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55405,
    name: "Minze",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53489,
    name: "Mozzarella Kugeln TK",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50210,
    name: "MOZZARELLASTANGEN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56021,
    name: "Nachos 9x125g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56203,
    name: "Niklas Buntstifte 4er Set",
    unit: "ST",
    conversion: [
      {
        factor: 1,
        text: "ST",
      },
    ],
  },
  {
    nr: 52557,
    name: "NORDSEE SALAT MIX",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56131,
    name: "Pasta Käse Spinat 12x400g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52613,
    name: "PAZIF. SCHOLLE PAN.",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50382,
    name: "PETERSILIE KG",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54181,
    name: "Pommes Sauce 20ml Btl.",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 51792,
    name: "R-GARN OK FRESHW.",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52601,
    name: "R-LACHS GESCHN.0,8-1,2KG",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55813,
    name: "Rahmrosenkohl",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54292,
    name: "Ratatouille TK",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52625,
    name: "RAUCHMATJESFIL./OEL 40 ER",
    unit: "EI",
    conversion: [
      {
        factor: 1,
        text: "EI",
      },
      {
        factor: 0.025,
        text: "g",
      },
    ],
  },
  {
    nr: 56005,
    name: "Red Coleslaw",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53475,
    name: "Riesengarn./ Dillcreme",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50945,
    name: "ROESTZWIEBELN",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55834,
    name: "Rösti",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55838,
    name: "Rotbarsch pan85g-115g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50011,
    name: "ROTE GRUETZE",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52635,
    name: "Rucola",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52570,
    name: "SALATDRESSING",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53864,
    name: "Sauce 150g für Matjes",
    unit: "SC",
    conversion: [
      {
        factor: 1,
        text: "SC",
      },
    ],
  },
  {
    nr: 56009,
    name: "Sauce Cheddar mild 0,95kg",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55414,
    name: "Sauce Honig Senf",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52549,
    name: "SC I",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52550,
    name: "SC II",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51222,
    name: "SC NACH HAUSFRAUENART",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52695,
    name: "Schokopudding m. Sahne",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53758,
    name: "Scholle mehliert",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55316,
    name: "Schollenfilet paniert, 130-150",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52067,
    name: "SEELACHSF.MEHLIERT",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55211,
    name: "Seelachsschnitzel grob",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56244,
    name: "Sirup Lemon Rantcho",
    unit: "FL",
    conversion: [
      {
        factor: 1,
        text: "FL",
      },
    ],
  },
  {
    nr: 56245,
    name: "Sirup Limone",
    unit: "FL",
    conversion: [
      {
        factor: 1,
        text: "FL",
      },
    ],
  },
  {
    nr: 55923,
    name: "Spargelragout Hollandaise",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50411,
    name: "STEAK POMMES 20",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55738,
    name: "Süßkartoffelpommes",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51245,
    name: "SYLTER HERINGSTOPF",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52624,
    name: "Sylter SC",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55326,
    name: "Thunfisch Beutel / Dose in KG",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52065,
    name: "TINTENFISCHRINGE BACKTG.",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50387,
    name: "TOMATEN frisch",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50556,
    name: "TOMATEN-KETCHUP 10KG",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53549,
    name: "Tomaten, Cocktail-/ Cherry-",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55183,
    name: "Tomatensalsa/Aufstrich Tomate",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56067,
    name: "Topping KnusperKräuter Italien",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56051,
    name: "TORTILLA  oTacos",
    unit: "ST",
    conversion: [
      {
        factor: 1,
        text: "ST",
      },
    ],
  },
  {
    nr: 51086,
    name: "TORTILLA Tomate / natur",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 56102,
    name: "Tuna plant based",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 51177,
    name: "VANILLESC        1L",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 56192,
    name: "Veganer Räucherlaxx 6*80g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50912,
    name: "WAFFELSCHALEN/KARTON",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
      {
        factor: 1000,
        text: "ST",
      },
    ],
  },
  {
    nr: 55314,
    name: "Walnussbrot",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 55147,
    name: "Wikinger-Soft-Brötchen 100x80g",
    unit: "KA",
    conversion: [
      {
        factor: 1,
        text: "KA",
      },
    ],
  },
  {
    nr: 52105,
    name: "Wikingerschnitte 115G",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
      {
        factor: 8.7,
        text: "ST",
      },
    ],
  },
  {
    nr: 56008,
    name: "Winter Rohkostmix",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 53127,
    name: "Winterapfeldessert",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55290,
    name: "Wrapcreme Dill-Honig Senf 500g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 54085,
    name: "Zanderfilet mehliert 120-150g",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52985,
    name: "Zitronen, Ganz",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 0.1,
        text: "ST",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 55758,
    name: "Zitronensaft 100%",
    unit: "LT",
    conversion: [
      {
        factor: 1,
        text: "LT",
      },
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 50390,
    name: "ZITRONENSPALTEN, Gastro",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
  {
    nr: 52558,
    name: "ZWIEBELN, FRISCH   diverse",
    unit: "KG",
    conversion: [
      {
        factor: 1,
        text: "KG",
      },
      {
        factor: 1000,
        text: "g",
      },
    ],
  },
];
